import React from 'react'
import Fade from 'react-reveal/Fade'
// import Slide from 'react-reveal/Slide'

import Layout from '../components/layout'
import SEO from '../components/seo'
// import ImageSimpleGallery from '../components/imageSimpleGallery'
// import ImageGallery from '../components/imageGallery'

// import ImageSchedule from '../images/fecha-1.png'
// import ImageSchedule2 from '../images/fecha-1@2x.png'
// import ImageSchedule3 from '../images/fecha-1@3x.png'

import ImageMap from '../images/Mapa_Careyes.png'
import ImageMap2 from '../images/Mapa_Careyes@2x.png'
import ImageMap3 from '../images/Mapa_Careyes@3x.png'

// import ImageIntersectionBlue from '../images/intersection-1-gray.svg'
import ImageMapDecorator from '../images/path-527.svg'
// import ImageIntersection3 from '../images/intersection-3.svg'

// import ImgGallery1 from '../images/galeria-2-1_Img.jpg'
// import ImgGallery2 from '../images/galeria-2-2_Img.jpg'
// import ImgGallery3 from '../images/galeria-2-3_Img.jpg'
// import ImgGallery4 from '../images/galeria-2-4_Img.jpg'

// import ImgOtherGallery1 from '../images/galeria-1-1-Jueves.jpg'
// import ImgOtherGallery2 from '../images/galeria-1-2-Viernes.jpg'
// import ImgOtherGallery3 from '../images/galeria-1-3-Sabado.jpg'
// import ImgOtherGallery4 from '../images/galeria-1-4-Domingo.jpg'

// import { SCHEDULE, DAYS_PLACEHOLDERS } from '../constants'

const Program = () => (
  <Layout
    gradient={{
      background:
        'linear-gradient(to bottom, rgba(32, 41, 69, 0.65), rgba(32, 41, 69, 0))',
    }}
  >
    <SEO title="Programa" />
    <div className="program__main-image">
      <h1 className="main-image__header">Programa</h1>
      <h2 className="main-image__subheader">Otoño</h2>
    </div>
    <section className="program__about">
      <div className="about__background-overlay" />
      <Fade left duration={1000}>
        <header className="about__title">
          <h1 className="about__title-header" id="patterns">
            Patrones
          </h1>
          <h1 className="about__title-subheader">naturales 2020</h1>
        </header>
      </Fade>
      <div className="about__content-wrapper">
        <section className="about__content about__content--first">
          <Fade duration={2000} delay={400}>
            <p>
              Somos creadores de la arquitectura del Pacífico, la cual respeta
              su índole y el espacio experimental, convirtiéndola en un ícono
              del diseño actual.
            </p>

            <p>
              Esta edición tendrá un enfoque hacia la conexión entre la
              naturaleza, la arquitectura y el arte mediante un eje sustentable,
              conservador y activista.
            </p>
          </Fade>
        </section>
        <section className="about__content about__content--second">
          <Fade duration={2000} delay={500} outEffect={null}>
            <p>
              Abordaremos la armonía que constituye la arquitectura y la
              cultura, presentando una línea curatorial basada en estos Patrones
              Naturales.
            </p>
          </Fade>
        </section>
      </div>
    </section>

    {/* <section className="program__schedule" id="activities">
      <Fade>
        <h1 className="schedule__header schedule__header--desktop">Agenda</h1>
      </Fade>
      <div className="program__schedule-container-decorator" />
      <Fade>
        <img
          src={ImageIntersectionBlue}
          className="program__schedule-image-decorator"
          alt="another decorator"
        />
      </Fade>
      <div className="schedule__content-wrapper">
        <Fade left duration={3000}>
          <h1 className="schedule__header schedule__header--mobile">
            Agenda
          </h1>
        </Fade>
        <section className="schedule__times">
          <Fade cascade duration={3000}>
            {SCHEDULE.map((scheduleItem, index) => (
              <div className="schedule__times-item" key={index}>
                <p className="times-item__date">{scheduleItem.date}</p>
                {scheduleItem.activities.map((activity, actIndex) => (
                  <div className="times-item__row" key={actIndex}>
                    <p className="times-item__row-time">{activity.time}</p>
                    <p className="times-item__row-activity">{activity.name}</p>
                  </div>
                ))}
                <div className="times-item__decorator" />
              </div>
            ))}
          </Fade>
        </section>
        <Slide right duration={1000}>
          <img
            src={ImageSchedule}
            srcSet={`${ImageSchedule2} 2x,
                    ${ImageSchedule3} 3x`}
            className="schedule__image"
            alt="actividades"
          />
        </Slide>
      </div>
    </section> */}
    {/* <section className="program__activities"> */}
    {/* <img
        src={ImageIntersection3}
        className="activities__image-decorator"
        alt="imagen decoracion"
      /> */}
    {/* <Fade right>
        <h1 className="activities__header">Actividades por día</h1>
      </Fade> */}
    {/* <ImageGallery
        imageList={[ImgOtherGallery1, ImgOtherGallery2, ImgOtherGallery3, ImgOtherGallery4]}
        placeholderList={DAYS_PLACEHOLDERS.map(day => (
          <p className="image-gallery__placeholder-text">
              <b>{day.textBold}</b>
              &nbsp;{day.textNormal}
          </p>
        ))}
      /> */}
    {/* <ImageSimpleGallery imageList={[
        {image: ImgOtherGallery1, text: '<b>JUEVES 26</b> DE MARZO 2020'},
        {image: ImgOtherGallery2, text: '<b>VIERNES 27</b> DE MARZO 2020'},
        {image: ImgOtherGallery3, text: '<b>SABADO 28</b> DE MARZO 2020'},
        {image: ImgOtherGallery4, text: '<b>DOMINGO 29</b> DE MARZO 2020'},
        ]}/> */}
    {/* </section> */}
    {/* <section className="program__recommendations" id="recommendations">
      <Fade>
      <h3 className="recommendations__header">Recomendaciones</h3>
      <ImageGallery imageList={[ImgGallery1, ImgGallery2, ImgGallery3, ImgGallery4]} />
      </Fade>
    </section> */}
    <section className="program__map" id="map">
      <img
        src={ImageMapDecorator}
        className="map__image-decorator"
        alt="imagen decoracion"
      />
      <Fade>
        <h1 className="map__header">Mapa de ubicación</h1>

        <img
          src={ImageMap}
          srcSet={`${ImageMap2} 2x,
                        ${ImageMap3} 3x`}
          className="map__image"
          alt="cereyes mapa"
        />
      </Fade>
    </section>
  </Layout>
)

export default Program
